import './App.css';

function App() {
  return (
    <div className="app">
      <p>
        توسعه دهندگان نرم افزار زاگرس
      </p>
    </div>
  );
}

export default App;
